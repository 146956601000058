import React from "react"
import { Helmet } from "react-helmet"

const globalWrapper = ({ element }) => {
  return (
    <>
      {element}
      <Helmet>
        <script type="text/javascript">
          {`window.fwSettings={'widget_id':103000002990};
                    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
        </script>
        <script
          type="text/javascript"
          src="https://euc-widget.freshworks.com/widgets/103000002990.js"
          async
          defer
        ></script>
      </Helmet>
    </>
  )
}

export default globalWrapper
